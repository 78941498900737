import {Component, OnInit} from '@angular/core';
import {PackagesService} from './packages.service';
import {ToastrService} from 'ngx-toastr';
import {ICreatePaymentIntentResponse, PackagesModel, PackagesResult} from './packages.model';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {LoaderService} from '../../../@core/services/loader.service';

declare var Stripe: any;

@Component({
  selector: 'app-packages',
  templateUrl: './packages.page.html',
  styleUrls: ['./packages.page.scss']
})
export class PackagesComponent implements OnInit {

  public packages: PackagesResult[];
  public paymentIntent: ICreatePaymentIntentResponse;

  constructor(
    private packageService: PackagesService,
    private router: Router,
    private toast: ToastrService,
    private loader: LoaderService
  ) {
  }

  ngOnInit(): void {
    this.packageService.getPackageList().subscribe((res) => {
      this.packages = res.body.results;
    });
  }

  invokePayment(price, packageId) {
    let comp = this;
    this.packageService.createPayment(price, packageId)
      .subscribe((response) => {
        this.paymentIntent = response.body;
        Swal.fire({
          title: `<strong>Pay <i class="fas fa-dollar-sign"></i> ${price}</strong>`,
          imageUrl: 'assets/images/stripe/logo.svg',
          imageWidth: 120,
          imageHeight: 'auto',
          imageAlt: 'Stripe logo',
          allowOutsideClick: false,
          allowEscapeKey: false,
          footer: `<img src="assets/images/stripe/powered_by_stripe.svg" alt="powered by stripe" />`,
          html: `<div class="payment-block">
                      <form id="payment-form" class="payment-form" >
                        <div class="payment-detail">
                          <div class="element-control">
                            <div id="card-element" class="card-element"></div>
                          </div>
                          <div class="error-block">
                            <div id="card-errors" role="alert"></div>
                          </div>
                          <div class="paybtn-block mt-3">
                              <button id="submit" class="btn btn-secondary" style="min-width: 100px;">Pay</button>
                          </div>
                        </div>
                      </form>
                  </div>`,
          showCloseButton: true,
          showConfirmButton: false
        });
        // Your Stripe public key
        const stripe = Stripe(this.paymentIntent.publishableKey);
        // Create `card` element that will watch for updates
        // and display error messages
        const elements = stripe.elements();
        const card = elements.create('card');
        card.mount('#card-element');
        card.addEventListener('change', event => {
          const displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
        // Listen for form submission, process the form with Stripe,
        // and get the
        const paymentForm = document.getElementById('payment-form');
        const self = this;
        paymentForm.addEventListener('submit', (ev) => {
          ev.preventDefault();
          self.loader.show();
          stripe.confirmCardPayment(self.paymentIntent.clientSecret, {
            payment_method: {
              card: card,
              billing_details: {
                name: 'Metacoder Client',
              }
            }
          }).then((result) => {
            self.loader.hide();
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              Swal.fire({
                title: `<strong>Payment Error</strong>`,
                icon: 'error',
                html: `<h4>${result.error.message}</h4>`,
                showCloseButton: true,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              return;
            } else {
              // The payment has been processed!
              if (result.paymentIntent.status === 'succeeded') {
                Swal.fire({
                  title: `<strong>Payment Successful</strong>`,
                  icon: 'success',
                  showCloseButton: false,
                  confirmButtonText: 'Close',
                  html: 'Click Close button to Continue',
                  showConfirmButton: true
                }).then(res => {
                  // parent.postMessage('Success', location.origin);
                  // window.close();
                  // self.router.navigate(['/profile']);
                });
              }
            }
          });
        });

        //  listen for card change events
        card.on('change', ({error}) => {
          const displayError = document.getElementById('card-errors');
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = '';
          }
        });
      });
  }
}
