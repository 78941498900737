import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChartsComponent} from './charts/charts.component';
import {ThreeDChartsComponent} from './charts3d/charts3d.component';
import {RouterModule} from '@angular/router';
import {PaginationComponent} from './pagination/pagination.component';
import {CommentsComponent} from './comments/comments.component';
import {CommentFormComponent} from './comment-form/comment-form.component';
import {LoaderModule} from './loader/loader.module';
import {CommentHostDirective} from '../@core/directives/comment-host.directive';
import {PlotlyViaCDNModule} from 'angular-plotly.js';
import {NgxFileDropModule} from 'ngx-file-drop';
import {ModelComponent} from './model/model.component';
import {CommentsService} from './comments/comments.service';
import {ParameterControlComponent} from './parameter-control/parameter-control.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

PlotlyViaCDNModule.plotlyVersion = '1.53.0';
//PlotlyViaCDNModule.plotlyBundle = 'gl3d';

@NgModule({
  declarations: [
    ChartsComponent,
    ThreeDChartsComponent,
    PaginationComponent,
    CommentsComponent,
    CommentFormComponent,
    CommentHostDirective,
    ModelComponent,
    ParameterControlComponent
  ],
  exports: [
    ChartsComponent,
    ThreeDChartsComponent,
    PaginationComponent,
    CommentsComponent,
    LoaderModule,
    NgxFileDropModule,
    ParameterControlComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PlotlyViaCDNModule,
    NgxFileDropModule
  ],
  providers: [CommentsService]
})
export class SharedModule {
}
