import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {

    totalPages: number;
    currentResponseSize: number;
    doNotLoad = false;
    pageStart = 0;
    pageEnd = 0;
    offset = 0;
    disablePrev = false;
    disableNext = false;
    totalItems: number;
    @Input() queryParams: any;
    @Input() dbType: string;
    @Input() currentPage: number;
    @Input() pageSize: number;
    @Input() url: string;
    @Input() changes: any;
    @Output() paginationResult = new EventEmitter();


    constructor(
        private http: HttpClient,
        private toast: ToastrService
    ) {
    }

    ngOnInit() {
      this.changes.subscribe((data) => {
        if (!this.url) {
          console.error('Url is required to fetch the data');
          return;
        }
        this.queryParams = data;
        if (this.dbType !== 'mongo') {
          this.page(1);
        } else {
          let customURL = '';
          this.queryParams.forEach((element, index) => {
            customURL = customURL + `${element.key}=${element.value}&`;
          });
          customURL = `${this.url}?${customURL}`;
          customURL = `${customURL}offset=${this.offset}`;
          this.http.get(customURL)
            .subscribe((response: any) => {
              this.totalItems = response.count;
              this.currentResponseSize = response.objs.length;
              this.paginationResult.emit(response);
              this.offset += this.currentResponseSize;
              console.log('Curret offset is: ', this.offset);
            });
        }
      });
    }
    page(page: number) {
        // tslint:disable-next-line:variable-name
        this.currentPage = page;
        const pageSize = this.queryParams.find(x => x.key === 'limit').value;
        const offset = (page - 1) * pageSize;
        let customURL = '';
        this.queryParams.forEach((element, index) => {
            customURL = customURL + `${element.key}=${element.value}&`;
        });
        customURL = `${this.url}?${customURL}`;
        customURL = `${customURL}offset=${offset}`;
        this.http.get(customURL)
            .subscribe((response: any) => {
                this.totalItems = response.count;
                this.paginationResult.emit(response);
                this.currentPage = page;
                if (response.count !== undefined) {
                    this.totalPages = Math.ceil((response.count) / (pageSize));
                }
                if (response.total !== undefined) {
                    this.totalPages = Math.ceil((response.total) / (pageSize));
                }
            }, error => {
                this.toast.error(error.message);
                console.error('error from fetching page, ${page}');
            });
    }

    ngOnDestroy() {
        this.changes.unsubscribe();
    }

    mongoPage(dir) {
      if (dir === 'prev'){
        this.offset -= this.pageSize;
      }
      let customURL = '';
      this.queryParams.forEach((element, index) => {
          customURL = customURL + `${element.key}=${element.value}&`;
      });
      customURL = `${this.url}?${customURL}`;
      customURL = `${customURL}offset=${this.offset}`;
      this.http.get(customURL)
        .subscribe((response: any) => {
          this.totalItems = response.count;
          this.currentResponseSize = response.objs.length;
          this.paginationResult.emit(response);
          if (dir === 'next') {
            this.offset += this.currentResponseSize;
          }
          this.disablePrev = this.offset <= 0;
          this.disableNext = this.offset === this.totalItems;
        }, error => {
            this.toast.error(error.message);
            console.error('error from fetching page, ${page}');
        });
    }



    displayStr() {
      if (this.dbType === 'mongo'){
          return `Displaying documents ${this.offset - this.currentResponseSize} - ${this.offset} of ${this.totalItems}`;
      } else {
        const offset = (this.currentPage - 1) * this.pageSize;
        return `Showing ${offset + 1} - ${this.currentPage * this.pageSize} Items of ${this.totalItems} Items`;
      }

    }
}
