import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorService} from './services/error.service';
import {ApiService} from './services/api.service';
import {GlobalErrorHandlerService} from './services/global-error-handler.service';
import {TitleService} from './services/title.service';
import {UtilityService} from './services/utility.service';
import {ThemeService} from './services/theme.service';
import {AuthGuard} from './guards/auth.guard';
import {DataService} from './services/data.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenService} from './services/token.service';
import {LoaderInterceptorService} from './services/loader-interceptor.service';
import {TwoDigitPipe} from './pipe/two-digit.pipe';
import {LabelPipe} from './pipe/label.pipe';
import {SafeHtmlPipe} from './pipe/safe.html.pipe';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AcronymPipe} from './pipe/acronym.pipe';
import {DataScienceResolver} from './resolvers/data-science.resolver';
import {ControlDirective} from './directives/dynamic.directive';
import {ControlComponent} from './control/control.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ReactiveFormsModule} from '@angular/forms';
import {GdriveApiService} from "./services/gdrive-api.service";

@NgModule({
  declarations: [
    TwoDigitPipe,
    SafeHtmlPipe,
    LabelPipe,
    AcronymPipe,
    ControlDirective,
    ControlComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgSelectModule,
    ReactiveFormsModule
  ],
  providers: [
    DataService,
    ApiService,
    ErrorService,
    GdriveApiService,
    UtilityService,
    TitleService,
    ThemeService,
    DataScienceResolver,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    }
  ],
    exports: [
        TwoDigitPipe,
        LabelPipe,
        SafeHtmlPipe,
        AcronymPipe,
        ControlDirective,
    ]
})
export class CoreModule {}
