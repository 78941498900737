import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public primaryColor: BehaviorSubject<string> = new  BehaviorSubject('#007bff');
  public secondaryColor: BehaviorSubject<string> = new  BehaviorSubject('#1d1d1d');
  public fontColor: BehaviorSubject<string> = new  BehaviorSubject('#1d1d1d');
  public fontFamily: BehaviorSubject<string> = new  BehaviorSubject('');
  public barchartColor: BehaviorSubject<string> = new  BehaviorSubject('#007bff');

  // tslint:disable-next-line:max-line-length
  public headerColor: BehaviorSubject<{color: string; background: string; }> = new  BehaviorSubject({color: '#fff', background: '#007bff'});
  // tslint:disable-next-line:max-line-length
  public sidebarColor: BehaviorSubject<{color: string; background: string; }> = new  BehaviorSubject({color: '#fff', background: '#007bff'});

  constructor() { }
  changePrimaryColor(colorValue: string) {
    this.primaryColor.next(colorValue);
    localStorage.setItem('primaryColor', colorValue);
  }

  changeSecondaryColor(colorValue: string) {
    this.secondaryColor.next(colorValue);
    localStorage.setItem('secondaryColor', colorValue);
  }
  changeFontColor(colorValue: string) {
    this.fontColor.next(colorValue);
    localStorage.setItem('fontColor', colorValue);
  }
  changeHeaderColor(fontColor: string, backgroundColor: string) {
    this.headerColor.next({ color: fontColor, background: backgroundColor});
    localStorage.setItem('headerColor', JSON.stringify({ color: fontColor, background: backgroundColor}));
  }
  changeSidebarColor(fontColor: string, backgroundColor: string) {
    this.sidebarColor.next({color: fontColor, background: backgroundColor});
    localStorage.setItem('sidebarColor', JSON.stringify({ color: fontColor, background: backgroundColor}));

  }
  changeFontFamily(fontFamily) {
    this.fontFamily.next(fontFamily);
  }
  changeBarchartColor(colorValue) {
    this.barchartColor.next(colorValue);
  }
  resetTheme() {
    this.changeFontColor('#1d1d1d');
    this.changeHeaderColor( '#fff', '#007bff');
    this.changeSidebarColor('#fff', '#007bff');
  }
  resetGraphs() {
    this.changeFontColor('#007bff');
    this.changePrimaryColor('#007bff');
    this.changeSecondaryColor('#1d1d1d');
  }
}
