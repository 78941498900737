import { Injectable } from '@angular/core';
import {Path} from '../../@core/structs';
import {ApiService} from '../../@core/services/api.service';


@Injectable()
export class CommentsService {
    constructor(public apiService: ApiService) {
    }

    getComments(url, queryParams) {
        return this.apiService.get(url, queryParams);
    }
}

