import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CommentsService} from './comments.service';
import {ICommentsModel} from './comments.models';
import {environment} from '../../../environments/environment';
import Swal from 'sweetalert2';
import {Path} from '../../@core/structs';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() commentUrl;
  @Input() modelId;
  public comments: ICommentsModel [];
  public commentsCount: number;
  public userId: number;
  serverMediaUrl = environment.baseUrl+ '/media';
  constructor(
     private commentService: CommentsService,
     private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem('user')).id;
    this.commentService.getComments(this.commentUrl, {}).subscribe((res) => {
      this.comments = res.body.objs;
      this.commentsCount  = res.body.objs.length;
    });
  }

  replyToComment(id) {
    Swal.fire({
      title: 'Enter your Comments',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Post',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      },
      preConfirm: (comment) => {
        const  formData = {
          comment:  comment,
          parent:  id,
        };
        return fetch(`${environment.baseUrl}${Path.createComment}${this.modelId}/`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(formData),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch(error => {
            Swal.showValidationMessage(
                `Request failed: ${error}`
            );
          });
    },
    allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      // @ts-ignore
      if (result.dismiss !== 'cancel') {
        Swal.fire('Saved!', '', 'success');
        this.ngOnInit();
      }
    });
  }
}
