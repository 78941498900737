<!-- Contenedor Principal -->
<div class="comments-container">
  <h3>Comments</h3>
  <ul *ngIf="comments?.length" id="comments-list" class="comments-list">
    <li *ngFor="let comment of comments">
      <div class="comment-main-level">
        <!-- Avatar -->
        <div class="comment-avatar">
          <img
            src="{{ serverMediaUrl }}/{{ comment.user_profile.avatar }}"
            alt=""
          />
        </div>
        <!-- Contenedor del Comentario -->
        <div class="comment-box">
          <div class="comment-head">
            <h6
              class="comment-name"
              [class.by-author]="comment.user === userId"
            >
              <a href="#">{{ comment.user_profile.username }}</a>
            </h6>
            <span>{{ comment.newest_activity | date: "medium" }}</span>
            <i
              (click)="replyToComment(comment._id.$oid)"
              class="fa fa-reply"
            ></i>
          </div>
          <div class="comment-content">
            {{ comment.comment }}
          </div>
        </div>
      </div>
      <!-- reply list -->
      <ul *ngIf="comment.children" class="comments-list reply-list">
        <li *ngFor="let reply of comment.children">
          <!-- Avatar -->
          <div class="comment-avatar">
            <img
              src="http://localhost:8000/media/{{ reply.user_profile.avatar }}"
              alt=""
            />
          </div>
          <!-- Contenedor del Comentario -->
          <div class="comment-box">
            <div class="comment-head">
              <h6
                class="comment-name"
                [class.by-author]="comment.user === userId"
              >
                <a href="#">{{ reply.user_profile.username }}</a>
              </h6>
              <span>{{ reply.newest_activity | date: "medium" }}</span>
            </div>
            <div class="comment-content">
              {{ reply.comment }}
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
  <p class="no-cmt-text" *ngIf="!comments?.length">no comments yet write one below</p>
  <div class="row">
    <button class="btn-dark new-cmt-btn" (click)="replyToComment(null)">
      Post New Comment!
    </button>
  </div>
</div>