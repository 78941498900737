import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit, AfterViewInit {

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('leftMenu') leftMenu: ElementRef;
  profile: any;
  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('profile'));
  }

  ngAfterViewInit() {
    // this.toggleButton.nativeElement.addEventListener('click', (event) => {
    //   this.leftMenu.nativeElement.classList.toggle('highlight');
    // });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['auth', 'user', 'login']);
  }
}
