import {Component, EventEmitter, Input, OnInit, Output, ElementRef, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ControlData} from '../directives/dynamic.directive';
import {Constants, INPUT} from '../utils/constants';
import {DataService} from '../services/data.service';

declare var $: any;

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit, ControlData {

  @Input() name: string;
  @Input() type: string;
  @Input() default: string;
  @Input() comment: string;
  @Input() options: string[];
  @Output() value = new EventEmitter();
  public inputType;
  public inputTypes = INPUT;
  elementForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    public el: ElementRef,
    public renderer: Renderer2
  ) {
    this.renderer.addClass(this.el.nativeElement, 'col-md-3');
  }

  ngOnInit(): void {
    this.inputType = Constants.DATA_TYPES.find(value => value.TYPE_NAME === this.type) ?
      Constants.DATA_TYPES.find(value => value.TYPE_NAME === this.type).INPUT_TYPE : INPUT.TEXT;
    this.elementForm = this.fb.group({
      ele: [this.default]
    });
    this.data.submitted.subscribe((response) => {
      if (response) {
        const output = {
          name: this.name,
          value: this.elementForm.value.ele
        };
        this.value.emit(output);
      }
    });
  }
}
