import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnChanges {
  @Input() actual;
  @Input() predicted;
  @Input() chartName;
  @Input() type: number;
  @Input() chartType: number;
  @Input() histogram;
  @Input() scatterData;
  @Input() data;
  @Input() layout;
  @Input() targetColumn;
  public graph: any;

  constructor() {
  }

  ngOnInit(): void {
    this.setChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  setChart() {
    this.graph = {data: this.data, layout: this.layout};
  }
  counter(i: number): number [] {
    return new Array(i);
  }
}
