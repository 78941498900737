export enum Path {
  base = '/api/v1/',
  login = '/api/v1/users/api-token-auth/',
  models = '/api/v1/models/',
  sharedModels = '/api/v1/models/shared/',
  charts = '/api/v1/models',
  regressor = '/api/v1/create-datascience-model/',
  common = '/api/v1/create-codegen-model/',
  user = '/api/v1/users/create/',
  ai = '/api/v1/load-master-data/ai/',
  tensorflow = '/api/v1/load-master-data/tensorflow/',
  ml = '/api/v1/load-master-data/automl/',
  codegen = '/api/v1/load-master-data/codegen/',
  datascience = '/api/v1/load-master-data/datascience/',
  pretrainedModels = '/api/v1/load-master-data/pretrained_models/',
  topperLayers = '/api/v1/load-master-data/topper_layers/',
  preprocessing= '/api/v1/load-master-data/feature_models/',
  datasets = '/api/v1/load-master-data/dataset/',
  clusterModels = '/api/v1/load-master-data/cluster_models/',
  scikitDatasets = '/api/v1/load-master-data/scikit_samples/',
  transferLearningDatasets = '/api/v1/load-master-data/transfer_learning/',
  loadDataset = '/api/v1/load_dataset',
  metacode = '/api/v1/metacode/',
  nameUpdate = '/update_name/',
  methodData = '/api/v1/load-method-data/',
  packages = '/api/v1/packages/',
  profile = '/api/v1/users/profile/',
  userSearch = '/api/v1/users/user_list',
  transactions = '/api/v1/users/transactions/',
  autoMlModelList = '/api/v1/automl/',
  createPaymentIntent = '/api/v1/create_payment/',
  createTextProcessModel = '/api/v1/create-ai-model/',
  shareModel = '/api/v1/share/',
  deleteShare = '/api/v1/unshare/',
  datasetList = '/api/v1/saved-datasets',
  analyzeDataset = 'analyze/',
  analyzeDatasetDC = 'analyze-dc/',
  objectComments = '/api/v1/comments/get-comments/',
  createComment = '/api/v1/comments/create-comment/',
  account_activate = '/api/v1/users/account/activate',
  account_reset_password = '/api/v1/users/account/reset_password',
  account_set_password = '/api/v1/users/account/set_password',
  omicDatasets = '/api/v1/load-master-data/omic_samples/',
  drugDatasets = '/api/v1/load-master-data/drug_samples/',
  diseaseDatasets = '/api/v1/load-master-data/disease_samples/',
  omicPretrainedModels = '/api/v1/load-master-data/omic_pretrained_models/',
  omicLearningLayers = '/api/v1/load-master-data/omic_learning_layers/',
  omicFeatureModels= '/api/v1/load-master-data/omic_feature_models/',
  resumeChoices= '/api/v1/load-master-data/resume_choices/',

  dcRegModels= '/api/v1/load-master-data/dc_regression_models/',
  dcClassModels= '/api/v1/load-master-data/dc_classification_models/',
  dcFeaturizers= '/api/v1/load-master-data/dc_featurizers/',

  toxChoices= '/api/v1/load-master-data/tox_choices/',
  admeChoices= '/api/v1/load-master-data/adme_choices/',
  associationsChoices= '/api/v1/load-master-data/associations_choices/',
  qedChoices= '/api/v1/load-master-data/qed_choices/',
  bindingChoices= '/api/v1/load-master-data/binding_choices/',
  modelTypeChoices =  '/api/v1/load-master-data/model_type_choices/',
  

}


export enum V2Path {
  base = '/api/v2/',
  prescreeData = 'prescreen_data/',
  saveImageData = 'save_image-dataset/',
  storeDataset = 'save-dataset/',
  datasetList = 'saved-datasets/',
  previewDataset = 'preview/',
  listDatasets = 'list-datasets',
  analyzeDataset = 'analyze/',
  handleMissingValues = 'handle-missing-value/',
  calculateMethodScores = 'calculate_method_scores/',
  regressor = 'create-datascience-model/',
  methodData = 'load-method-data/',
  autoMlModel = 'create-automl-model/',
  autoMlModelList = 'automl/',
  createNNModel = 'create_nn_model/',
  dataScience_mongo = 'datascience-models/',
  dataScience_charts = 'datascience-charts/',
  dataScience_model_runs = 'datascience-model-runs/',
  scikitDatasetsPreview = 'scikit-datasets/preview',

  dcDatasetsPreview = 'dc-datasets/preview',

  omicDatasetsPreview = 'omic-datasets/preview',
  modelScore= 'get-score-metric/',
  dsmodels = 'dsmodels/',
  nameUpdate = '/update_name/',
  preprocessing= 'load-master-data/feature_models/',
  addPreprocessing= 'preprocessing/',
  checkModelStatus= 'model-run-status/',
  secondaryCharts= 'dataset/',
  nnNNavalues= 'get_nna_values/',
  tlCreate = 'transfer-learning/create-model',
  tlModelList = 'transfer-learning/list/',
  tlModelResult = `transfer-learning/result/`,
  getPredictionData = 'get-pred-data/',
  predictionScore = 'predict-instance/',
  datasetProps = 'prescreen_data_images/',
  datasetPropsM = 'multiomics/prescreen_omic_data', //omic-datasets/preview
  getOmicDataset = 'get-omic-dataset/', //get dataset navigate front end based on stage
  createMOmodel = 'multiomics/create-model', //impute passed steps 
  createStandardDC = 'multiomics/create_dc_model',
  moModelList = 'multiomics/list/',
  moModelResult = `multiomics/result/`,
  moMethodData = 'load-omic-method-data/',
  moTables = 'multiomics-tables/',
  moGetCharts = 'multiomics/get-modality/',

  doBulkMongo = 'multiomics/bulk_mongo/',

  dataScience_charts_dc = 'datascience-charts-dc/',


  drugDiseasesList = 'multiomics/get_drug_diseases/',
  
  moGetProteins = 'multiomics/get_drug_proteins/',
  moGetSideEffects = 'multiomics/get_drug_side_effects/',
  moGetPathways = 'multiomics/get_drug_pathways/',
  moGetGeneExpressions = 'multiomics/get_drug_gene_expressions/',
  moGetGOAssociations = 'multiomics/get_drug_go_associations/',

  getRadarData = 'multiomics/get-radar-data/', //
  getRadarData2 = 'multiomics/get-radar-data-2/', //ddr similarity attributes
  getRadarData3 = 'multiomics/get-radar-data-3/', //for attributes
  getTFService = 'multiomics/run_script', 
  getConfusion = 'multiomics/get-metric/', 

  getMDAResult = "multiomics/get-mda-charts/",
  getVAEResult = "multiomics/get-vae-charts/",
  getFinalResult = "multiomics/get-final-charts/",
  getSingleModel = "multiomics/get-single-model/",
  getRWRResult = "multiomics/get-rwr-charts/",
  search_mo = "multiomics/search/",
  presenceIDs = "multiomics/get_presence_ids/",
  getLearningModels = "multiomics/get-learning-models/",

  upload_compound = "multiomics/upload_compound/",
  upload_compound_page = "multiomics/upload_compound_page/",

  mergeUploadedCompounds = 'multiomics/merge_uploaded_compounds/', 
  getDrugAttributes = 'multiomics/get_drug_attributes/', 
  postUserDrugAttributes = 'multiomics/post_user_drug_attributes/', 
  getSummaryDrugInSet = 'multiomics/get_summary_drug_in_set/'
}
