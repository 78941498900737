import { Routes } from '@angular/router';
import {AuthGuard} from '../@core/guards/auth.guard';


export const PRIVATE_ROUTES: Routes = [
  {
    path: 'dataset',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/dataset/dataset.module').then(
            (m) => m.DatasetModule,
          ),
      },
    ],
  },
  {
    path: 'datascience',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/aimodals/aimodals.module').then(
            (m) => m.AIModalsModule,
          ),
      },
    ],
  },
  {
    path: 'tensorflow',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/tensorflow/tensorflow.module').then(
            (m) => m.TensorflowModule,
          ),
      },
    ],
  },
  {
    path: 'multiomics',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/multiomics/multiomics.module').then(
            (m) => m.MultiomicsModule,
          ),
      },
    ],
  },


  {
    path: 'automl',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/automl/automl.module').then(
            (m) => m.AutomlModule,
          ),
      },
    ],
  },
  {
    path: 'codegen',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/codegen/codegen.module').then(
            (m) => m.CodegenModule,
          ),
      },
    ],
  },
  {
    path: 'text',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/text-process/textprocessing.module').then(
            (m) => m.TextProcessingModule,
          ),
      },
    ],
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/profile/profile.module').then(
            (m) => m.ProfileModule,
          ),
      },
    ],
  },
  {
    path: 'datagen',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/datalake/datalake.module').then(
            (m) => m.DatalakeModule,
          ),
      },
    ],
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../pages/payments/payments.module').then(
            (m) => m.PaymentsModule,
          ),
      },
    ],
  },
];

