import {ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {s3Upload} from '../../../@core/utils/s3';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {DatasetService} from '../../dataset/dataset.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TensorflowService} from '../tensorflow.service';

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.css']
})
export class DataUploadComponent implements OnInit {
  @ViewChild('progressBar', {read: ElementRef}) progressBar: ElementRef;
  @ViewChild('target', {read: ElementRef}) target: ElementRef;
  tlDatasetSelectionModalRef: BsModalRef | null;
  @ViewChild('tlDatasetSelectionModal') tlDatasetSelectionModal: TemplateRef<any>;
  public files: NgxFileDropEntry[] = [];
  public serverFileId: string;
  public progress = 0;
  public message = '';
  public uploadInProgress = false;
  public currentFile: File;
  public fileUrl: string;
  public tlExampleSets: Record<string, any> = {};

  constructor(
    private tfService: TensorflowService,
    private datasetService: DatasetService,
    private modalService: BsModalService,
    private router: Router,
    private toastr: ToastrService,
    private avrs: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }
  ngOnInit(): void {
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }

  addClass(cls, element) {
    element.classList.add(cls);
  }

  removeClass(cls, element) {
    $(element).slideUp();
    element.classList.remove(cls);
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.zip', '.tar.gz', '.tgz'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.progress = 0;
    if (files.length > 1) {
      this.toastr.error('Cannot add more than 1 Files at a time.');
    } else {
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.currentFile = file;
            this.uploadFile(file);
          });
        } else {
          this.toastr.error('Only \'.zip\', \'.tar.gz\', \'.tgz\' files are accepted');
        }
      }
    }
  }

  public openModel(templateRef: TemplateRef<any>, type, cls = 'modal-lg') {
    const config: ModalOptions = {
      backdrop: 'static',
      class: 'modal-xl',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true
    };
    if (cls) {
      config.class = cls;
    }
    this.tlDatasetSelectionModalRef = this.modalService.show(templateRef, config);

  }


  uploadFile(file) {
    const params = {
      Bucket: 'mcdatasets',
      Key: file.name,
      Body: file,
      ACL: 'private',
      ContentDisposition: 'attachment'
    };
    s3Upload.upload(params).on('httpUploadProgress', (evt) => {
      this.uploadInProgress = true;
      this.progress = Math.round(evt.loaded / evt.total * 100);
    }).send((err, data) => {
      if (err) {
        console.log(err, err.stack);
        return;
      }
      this.fileUrl = `https://nyc3.digitaloceanspaces.com/${data.Bucket}/${data.Key}`;
      const formData = new FormData();
      formData.append('filepath', this.fileUrl);
      this.datasetService.uploadImageData(formData).subscribe((response) => {
          this.serverFileId = response.body.fileid;
          this.uploadInProgress = false;
          this.router.navigate(['/tensorflow', 'create'], {queryParams: {datasetId: this.serverFileId}});
        },
        (error) => {
          this.progress = 0;
          this.message = error.error;
        });
    });
  }
  public closeModel(modalRef: BsModalRef<any>) {
    if (!modalRef) {
      return;
    }
    modalRef.hide();
    modalRef = null;
  }

  getSampleDataSet() {
    this.datasetService.getTransferLearningSamples().subscribe(res => {
        this.tlExampleSets = res.body;
        this.openModel(this.tlDatasetSelectionModal, 'tldataset');
    }, error => {
      console.log('Error fetching Transfer learning example datasets');
    });
  }

  browseComputer() {
    $('#upload-image-tl').trigger('click');
  }

  setDataset(name) {
      this.closeModel(this.tlDatasetSelectionModalRef);
      this.tfService.getDatasetSummary(name).subscribe(res => {
          console.log('Set dataset called:', name);
  
          const class_nums = res.body.no_classes;
          const samples = res.body.no_samples;
          console.log('number of classes received :', class_nums);
          console.log('number of samples received :', samples);
          this.router.navigate(['/tensorflow', 'create'], {queryParams: {datasetId: name, datasetType: 'exampleSet', no_classes: class_nums, no_samples: samples}});
        }, error => {
          console.log('Error fetching Transfer learning example datasets');
        });
    }
}