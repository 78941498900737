<section class="content-section">
  <div class="fl-width-width-pading"></div>
  <div class="packages-card-row">
    <div class="card" *ngFor="let item of packages">
      <div class="card-header">
        <a class="packages-img">
          <!-- <img [src]="item.image" alt="rover" /> -->
          <img
            src="../../../../assets/images/cover-pic.png"
            class="img-fluid wallet-img"
            alt="rover"
          />
        </a>
      </div>
      <div class="card-body text-center">
        <h2 class="package-name">{{item.name}}</h2>
        <h4 class="credits"><b>Credits: </b>{{item.credits}}</h4>
        <p class="package-destext">
          An exploration into the truck's polarising design
        </p>
        <div class="package-price">
          <span class="discount-price"><b>$ {{item.sp}}</b></span>
          <span class="original-price">$ {{item.price}}</span>
        </div>
        <div class="user">
          <div class="user-info">
            <button
              (click)="invokePayment(item?.sp, item.id)"
              class="btn pricing-button cursor-pointer"              
            >
              Purchase
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>