import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import {Observable} from 'rxjs';
import {Path} from '../structs';

@Injectable()
export class DataScienceResolver implements Resolve<any> {
    constructor(private http: HttpClient, private data: DataService) {
    }
    resolve(): Observable<any> {
        return this.http.get(`${environment.baseUrl}${Path.datascience}`).pipe(
            catchError(this.data.handleError.bind(this))
        );
    }
}
