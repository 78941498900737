import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {GdriveApiService} from "../@core/services/gdrive-api.service";

@Component({
  selector: 'app-gdrive',
  templateUrl: './gdrive.component.html',
  styleUrls: ['./gdrive.component.css']
})
export class GdriveComponent implements OnInit, OnDestroy {

  clientId = '127006519746-tn4h6dsnek0dv69gfgthlvkgkm5l655l.apps.googleusercontent.com';
  redirectUri = 'http://localhost:4200/gdrive';
  scope = "https://www.googleapis.com/auth/drive.readonly";
  authEndpoint = "https://accounts.google.com/o/oauth2/v2/auth"
  url: string;

  constructor(
    public route: ActivatedRoute,
    public gapi: GdriveApiService) {
  }

  ngOnInit(): void {
    this.url = `${this.authEndpoint}?scope=${this.scope}&include_granted_scopes=true&response_type=token&redirect_uri=${this.redirectUri}&client_id=${this.clientId}`
    this.route.fragment.subscribe(res => {
      console.log("response from google auth popup", res);
      const access_token = new URLSearchParams(res).get('access_token')
      console.log(res)
      if (access_token) {
        console.log("access_token is ", access_token);
        try {
        } catch (err) {
          console.log(err)
        }
        window.close();
      }
    });

  }


  public handleGOauthResponse(accessToken) {
    console.log('access_token is ==> ' + accessToken);
    this.gapi.getCsvFiles(accessToken).subscribe(gres => {
      console.log("response from file listing is ", gres)
    });
  }

  authenticate() {
    let ref = window.open(this.url, '', 'status=yes,width=500,height=500,rel=opener',)
  }

  ngOnDestroy() {
    // @ts-ignore
    window.angularComponent = null;
  }
}
