import { S3 } from '@aws-sdk/client-s3';
import * as AWS from 'aws-sdk';


const s3Client = new S3({
    endpoint: 'https://nyc3.digitaloceanspaces.com',
    region: 'us-east-1',
    credentials: {
      accessKeyId: 'JMQXGS3L3U4YPLVAS5U3',
      secretAccessKey: 'ZAJxg/mmf877j5hd738+g/FeipJ0yvCbUhqE8nuEbwo'
    }
});


const spacesEndpoint = new AWS.Endpoint('https://nyc3.digitaloceanspaces.com');

const s3Upload = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: '62WTQKL4YMJF6CX7XTNM',
      secretAccessKey: 'mVtxGEeoIitUYEnrc8oWgV02aMDst0VuRUJj53ml0LU'
});

const spacesConfig = {
  digitalOceanSpaces: 'https://mcdatasets.nyc3.digitaloceanspaces.com',
  bucketName: 'mcdatasets'
};

export { s3Client, s3Upload, spacesConfig };
