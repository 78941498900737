<div class="form-group" [formGroup]="elementForm">
  <label class="cd-label label-sm bold">{{name | lablePipe}}
    <span data-toggle="tooltip" data-placement="top" title="Tooltip on top">
      <img src="assets/images/important.svg" height="20" class="ms-1">
    </span>
  </label>
  <div *ngIf="inputType === inputTypes.SELECT; else TEXT_TYPE;">
    <ng-select
    formControlName="ele"
    [items]="options"
    ></ng-select>
  </div>
  <ng-template #TEXT_TYPE>
    <input type="text" class="form-control" formControlName="ele">
  </ng-template>
</div>


