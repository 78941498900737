import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './router';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
  };

@NgModule({
  imports: [RouterModule.forRoot(
    [...PUBLIC_ROUTES, ...PRIVATE_ROUTES ], routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
