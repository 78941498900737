import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from './@core/services/auth.service';
import {GlobalErrorHandlerService, IpaymentRequired} from './@core/services/global-error-handler.service';
import {HttpErrorResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import {PackagesComponent} from './pages/payments/packages/packages.page';
import {ControlDirective} from './@core/directives/dynamic.directive';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {GuidedTour, GuidedTourService, TourStep} from "ngx-guided-tour";

declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'metacoder';
  isLoggedIn: Observable<boolean>;
  windowRef = null;
  modalRef2: BsModalRef;
  ref: ComponentRef<any>;
  @ViewChild(ControlDirective) control!: ControlDirective;
  @ViewChild('packagesTemplate') packageTemplate: TemplateRef<any>;
  sampleTour: GuidedTour;
  constructor(
    private router: Router,
    private authService: AuthService,
    private GEHS: GlobalErrorHandlerService,
    private cfr: ComponentFactoryResolver,
    private modalService: BsModalService,
    private gtour: GuidedTourService
  ) {
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn$;
    this.GEHS.paymentRequiredObservable.subscribe((error: IpaymentRequired) => {
      if (!(error instanceof HttpErrorResponse) || error.status === 402) {
        Swal.fire({
          title: '<strong>Oooppsssss !<u>!</u></strong>',
          icon: 'warning',
          html:
            `You are running out of the credits
              <b>Please topup account to continue</b>`,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Purchase',
          cancelButtonText: 'cancel',
          cancelButtonAriaLabel: 'cancel'
        }).then((result) => {
          // @ts-ignore
          if (result.dismiss === 'cancel') {
            return;
          } else {
            // this.router.navigate(['/packages']);
            // this.openModal(this.packageTemplate);
            this.triggerPaymentWindow();
          }
        });
      }
    });
  }

  triggerPaymentWindow() {
    const cf = this.cfr.resolveComponentFactory(PackagesComponent);
    this.ref = this.control.vcr.createComponent(cf);
    $('#packagesModal').modal('show');
    // this.windowRef = window.open(
    //   '/payments/packages',
    //   'child',
    //   'toolbar=no,
    //   location=no,directories=no,status=no,menubar=no,
    //   titlebar=no,fullscreen=no,scrollbars=1,resizable=no,width=1024,height=1024,left=300,top=100');
    // this.windowRef.addEventListener('message', this.receivemessage.bind(this), false);
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      class: 'modal-lg',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true
    };
    this.modalRef2 = this.modalService.show(template, config);
  }

  closeModal() {
    this.modalRef2.hide();
    this.ref.destroy();
  }

  receivemessage(evt: any) {
    console.log(evt.data);
  }
}
