<section class="content-section">
  <div class="fl-width-width-pading">
    <!-- Right Content -->
    <div class="right-list-page mt-0">

      <div class="d-flex align-items-center">
        <div class="to-sbtm float-none me-md-5 me-2">
          <p>
            <a class="d-flex align-items-center text-dark text-decoration-none" data-toggle="collapse"
               href="#target" role="button" aria-expanded="true" aria-controls="collapseTop">
              <img src="assets/images/back.png" class="img-fluid back-arrow" alt=""/>
              <span>Add your data!</span></a></p>
        </div>
        <div class="to-sbtm float-none">
          <p>
            <a class="d-flex align-items-center text-dark text-decoration-none" data-toggle="collapse"
               href="#collapseNumber" role="button" aria-expanded="false" aria-controls="collapseNumber">
              <img src="assets/images/back.png" class="img-fluid down-arrow"><span>View Progressbar</span></a></p>
        </div>
      </div>
      <div class="row g-0 mt-2">
        <div class="col-12">
          <div class="progress-report">
            <!-- Progress Bar -->
            <div class="collapse" id="collapseNumber" #progressBar>
              <div class="progress-content d-flex align-items-center px-2 px-md-4 py-2 py-md-4 justify-content-between">
                <div class="position-relative steps-item">
                  <div class="circle-box active">01</div>
                  <div class="svg-arrow">
                    <img src="assets/images/arrow-right.png" class="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="position-relative steps-item">
                  <div class="circle-box ">02</div>
                  <div class="svg-arrow">
                    <img src="assets/images/arrow-right.png" class="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="position-relative steps-item">
                  <div class="circle-box">03</div>
                  <div class="svg-arrow">
                    <img src="assets/images/arrow-right.png" class="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="position-relative steps-item">
                  <div class="circle-box">04</div>
                </div>
              </div>
            </div>

            <div class="row pt-3 pt-md-0">
              <div class="col-12 align-items-center py-md-3 py-0 ">
                <p class="Upload">Upload and Clean Data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" id="target" #target>
        <div class="row">
          <div class="col-xl-9 col-md-9">
            <!-- Content -->
            <div class="fl-width-width-pading">
              <!-- Right Content -->
              <div class="right-list-page mt-0">
                <!-- Mobile Search -->
                <div class="search-form d-block d-md-none">
                  <input type="email" class="form-control" id="" aria-describedby=""
                         placeholder="I Am searching for..."/>
                  <button>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M30.5169 27.8259L21.5941 18.7342L21.428 18.914C24.926 13.9169 24.0305 7.01377 19.3785 3.11419C14.7264 -0.785382 7.90553 -0.350488 3.76694 4.10957C-0.371649 8.56962 -0.415475 15.5327 3.66664 20.0464C7.74875 24.5602 14.5636 25.0842 19.2643 21.2457L19.2317 21.2807L28.0875 30.3013C28.7618 30.9648 29.8337 30.9554 30.4966 30.2799C31.1595 29.6045 31.1688 28.5124 30.5175 27.8254L30.5169 27.8259ZM12.1172 20.8917C7.37155 20.8917 3.52441 16.9719 3.52441 12.1367C3.52441 7.30147 7.37155 3.38174 12.1172 3.38174C16.8629 3.38174 20.7101 7.30147 20.7101 12.1367C20.7047 16.9697 16.8607 20.8862 12.1172 20.8917Z"
                        fill="#181C5B"
                      />
                    </svg>
                  </button>
                </div>
                <div class="clearfix"></div>
                <div class="file-info">
                  <div class="file-upload">
                    <ngx-file-drop
                      dropZoneClassName="dataset-upload-area"
                      dropZoneLabel="Drop File here Or Browse"
                      contentClassName="dataset-upload-content"
                      (onFileDrop)="dropped($event)">
                      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <img id="upload-image-tl"
                          style="width: 430px;max-width: 100%;"
                          src="assets/images/tensorflow/whole-design-image-WB.png" alt="dataset-structure-demo"
                          (click)="openFileSelector()"
                        />
                      </ng-template>
                    </ngx-file-drop>
                  </div>
                  <div *ngIf="files" class="file-name">
                    {{files[0]?.fileEntry.name}}
                  </div>
                  <div *ngIf="currentFile" class="progress">
                    <div
                      class="progress-bar progress-bar-info progress-bar-striped"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                  <div class="loader-wrapper" *ngIf="uploadInProgress">
                    <span>Uploading data please wait...</span>
                  </div>
                </div>

                <div class="clearfix"></div>

                <div class="upload-img d-none d-lg-block">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle upload-btn" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Upload dataset
                    </button>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a (click)="browseComputer()" class="dropdown-item"><i class="fa fa-hdd"></i> Browse
                        Computer</a>
                    </div>
                  </div>
                  <div class="dropdown">
                     <button
                      class="btn btn-secondary upload-btn"
                      type="button"
                      (click)="getSampleDataSet()">
                      Example Sets
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Sidebar -->
          <div class="col-xl-3 col-md-3 po-relative overflow-hidden">
            <!-- <img src="assets/images/dotted.png" class="dotted-black-image" alt=""/> -->
            <img src="assets/images/Vector-dark-black.png" class="table-light-center-image" alt=""/>
            <div class="right-bg">
              <!-- Desktop Search -->
              <div class="search-form d-none d-md-block">
                <input type="email" class="form-control" id="" aria-describedby="" placeholder="I Am searching for..."/>
                <button>
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.5169 27.8259L21.5941 18.7342L21.428 18.914C24.926 13.9169 24.0305 7.01377 19.3785 3.11419C14.7264 -0.785382 7.90553 -0.350488 3.76694 4.10957C-0.371649 8.56962 -0.415475 15.5327 3.66664 20.0464C7.74875 24.5602 14.5636 25.0842 19.2643 21.2457L19.2317 21.2807L28.0875 30.3013C28.7618 30.9648 29.8337 30.9554 30.4966 30.2799C31.1595 29.6045 31.1688 28.5124 30.5175 27.8254L30.5169 27.8259ZM12.1172 20.8917C7.37155 20.8917 3.52441 16.9719 3.52441 12.1367C3.52441 7.30147 7.37155 3.38174 12.1172 3.38174C16.8629 3.38174 20.7101 7.30147 20.7101 12.1367C20.7047 16.9697 16.8607 20.8862 12.1172 20.8917Z"
                      fill="#181C5B"
                    />
                  </svg>
                </button>
              </div>
              <div class="attachment-info">
                <div class="attachbox">
                  <ul>
                    <li [routerLink]="['/datagen','generate','countries']" class="cursor-pointer">
                      <span class="img-svg"><img src="assets/images/svg/Economics.png" alt=""/></span>
                      <span>Economics </span>
                    </li>
                    <li>
                      <span class="img-svg"><img src="assets/images/svg/Genomics.png" alt=""/></span>
                      <span> Genomics </span>
                    </li>
                    <li>
                      <span class="img-svg"><img src="assets/images/svg/Digital-marketing.png" alt=""/></span> <span>Digital Marketing </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #tlDatasetSelectionModal>
  <div class="modal-header border-0">
    <h5 class="modal-title" id="scikit-samples">Transfer Learning Samples</h5>
    <button type="button" class="btn-close" (click)="closeModel(tlDatasetSelectionModalRef)"
            data-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="modal-scroll-block modal-scikit-lists">
      <div class="row small-margin">
        <div class="row small-margin">
           <div class="col-6 col-md-6 col-lg-3" *ngFor="let dataset of tlExampleSets | keyvalue">
            <a (click)="setDataset(dataset.key)" class="modal-block"> 
              <div class="md-ttl">{{dataset.key |acronymPipe }}</div>
              <div class="md-dsc">{{dataset.key }}</div>
               <i class="fas fa-info-circle" [title]="dataset?.value?.description"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
