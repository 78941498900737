import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { FooterModule } from '../footer/footer.module';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    FooterModule,
    RouterModule
  ],
  exports: [LayoutComponent]
})
export class LayoutModule { }
