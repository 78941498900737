<div class="full-bg" id="loader" [ngClass]="show ? '' : 'd-none'">
  <div class="sk-chase" [ngClass]="show ? '' : 'd-none'">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
</div>
