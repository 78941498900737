
  <div [ngSwitch]="param.controlType" [formGroup]="form">
    <div class="mb-4" *ngSwitchCase="'int'">
      <label class="cd-label label-sm bold" [attr.for]="param.key">{{param.label}}
        <span data-toggle="tooltip" data-placement="top" [title]="param.tooltip" data-original-title="Tooltip on top">
              <img src="assets/images/important.svg" height="20" class="ms-1" alt="">
            </span>
      </label>
      <input [id]="param.key" [type]="param.type" class="form-control" [placeholder]="param.key"
             [formControlName]="param.key" [value]="param.defaultValue">
    </div>
    <div class="mb-4" *ngSwitchCase="'float'">
      <label class="cd-label label-sm bold" [attr.for]="param.key">{{param.label}}
        <span data-toggle="tooltip" data-placement="top" [title]="param.tooltip" data-original-title="Tooltip on top">
              <img src="assets/images/important.svg" height="20" class="ms-1" alt="">
            </span>
      </label>
      <input [id]="param.key" [type]="param.type" [value]="param.defaultValue" class="form-control" [placeholder]="param.key"
             [formControlName]="param.key">
    </div>
    <div class="mb-4" *ngSwitchCase="'dropdown'">
      <label class="cd-label label-sm bold" [attr.for]="param.key">{{param.label}}
        <span data-toggle="tooltip" data-placement="top" [title]="param.tooltip" data-original-title="Tooltip on top">
              <img src="assets/images/important.svg" height="20" class="ms-1" alt="">
            </span>
      </label>
      <select class="form-select form-control" [id]="param.key" [formControlName]="param.key">
        <option *ngFor="let opt of param.options" [value]="opt.key" [selected]="opt.value===param.defaultValue">{{opt.value}}</option>
      </select>
    </div>
    <div class="errorMessage" *ngIf="!isValid">{{param.label}} is required</div>
  </div>

