import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-charts3d',
  templateUrl: './charts3d.component.html',
  styleUrls: ['./charts3d.component.scss']
})
export class ThreeDChartsComponent implements OnInit, OnChanges {
  @Input() actual;
  @Input() predicted;
  @Input() chartName;
  @Input() type: number;
  @Input() chartType: number;
  @Input() histogram;
  @Input() scatterData;
  @Input() data;
  @Input() targetColumn;
  @Input() layout;

  public graph: any;
  public defaultLayout = {
    title: 'Your 3D Plot Title',
    scene: {
      xaxis: { title: 'X Axis Label' },
      yaxis: { title: 'Y Axis Label' },
      zaxis: { title: 'Z Axis Label' }
    },
    template: {
      layout: {
        xaxis: { title: 'X Axis Label' },
        yaxis: { title: 'Y Axis Label' },
        zaxis: { title: 'Z Axis Label' } // adding zaxis here
      }
    }
};

  constructor() {}

  ngOnInit(): void {
    this.setChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.layout) {
      this.setChart();
    }
  }

  setChart() {
    if (this.data && Array.isArray(this.data)) {
      this.data.forEach(trace => {
        if (trace.z) {
          trace.hovertemplate = '%{x}<br>%{y}<br>%{z}';
          trace.type = 'scatter3d'; // Ensure it's a scatter3d type
        }
      });
    }
  
    const finalLayout = {
      ...this.defaultLayout,
      ...this.layout,
      scene: {
        ...this.defaultLayout.scene,
        ...this.layout?.scene
      }
    };
  
    // Check if zaxis is available in finalLayout, if not forcefully add it.
    if (!finalLayout.scene.zaxis) {
      finalLayout.scene.zaxis = { title: 'Z Axis Label' };
    }
  
    this.graph = { data: this.data, layout: finalLayout };
  }
  
  
  counter(i: number): number[] {
    return new Array(i);
  }
}
