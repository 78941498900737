import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ControlBase} from './parameter-control.modal';

@Component({
  selector: 'app-parameter-control',
  templateUrl: './parameter-control.component.html',
  styleUrls: ['./parameter-control.component.css']
})
export class ParameterControlComponent implements OnInit {

  @Input() param!: ControlBase<string>;
  @Input() form!: FormGroup;

  constructor() {
  }

  ngOnInit(): void {
  }

  get isValid() {
    return this.form.controls[this.param.key].valid;
  }
}
