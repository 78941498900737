import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Path, V2Path} from '../structs';
import {BehaviorSubject, throwError} from 'rxjs';
import {ApiService} from './api.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  public submitted = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
  }

  /*
   * Return subjects of the languages
   * @param language: language name
   **/
  getMethods(language) {
    const data = JSON.parse(localStorage.getItem('master'));
    return Object.keys(data[language]);
  }

  getPreprocess(language) {
    const data = JSON.parse(localStorage.getItem('master'));
    return data[language]['preprocess']['preprocessing'].map(value => value[Object.keys(value)[0]]);
  }

  /*
   * Return single category object from name
   * @param language: language name
   * @param method: method name
   * @param category: category name
   * @param name: object name
   * */
  getCategory(language, method, category, name) {
    const data = JSON.parse(localStorage.getItem('master'));
    return data[language][method][category].find(value => value.name === name);
  }

  /*
   * Calls an API: load method data
   */
  getSingleCategory(language, method, category, name, type) {
    const data = {
      filename: name,
      method_type: type,
      method_class: category
    };
    return this.apiService.post(Path.methodData, data);
  }

  // tslint:disable-next-line:variable-name
  getSingleMethodParams(filename, method_type, method_class) {
    const data = {
      filename,
      method_type,
      method_class
    };
    return this.apiService.post(`${V2Path.base}${V2Path.methodData}`, data);
  }

  /**
   * Handles error occurred by http calls
   * @param error dfd
   */
  handleError(error: HttpErrorResponse) {
    console.log('In error');
    if (error.status === 403) {
      return throwError(
        error.error);
    }
    if (error.status === 401) {
      localStorage.removeItem('token');
      // window.location.href = '/login';
      // this._toast.info ('Please login again!', 'Your session has been expired');
      return throwError(
        error.error);
    }
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      error.error);
  }
}
