import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'acronymPipe'})
export class AcronymPipe implements PipeTransform {

  constructor() {
  }
  transform(value: string): string {
    return value.match(/\b(\w)/g).join('').toUpperCase();
  }
}
