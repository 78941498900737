import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'lablePipe'})
export class LabelPipe implements PipeTransform {

  constructor() {
  }
  transform(value: string): string {
    return value ? value.replace(/_/g, ' ') : value;
  }
}
