import {Injectable} from '@angular/core';
import {ApiService} from '../../@core/services/api.service';
import {Path, V2Path} from '../../@core/structs';


@Injectable()
export class DatasetService {
  constructor(
    public apiService: ApiService) {
  }

  analyzeDataSet(data, type) {
    if (type === 'omic'){
      return this.apiService.post(`${V2Path.base}${Path.analyzeDatasetDC}`, data)
    }else{
    return this.apiService.post(`${V2Path.base}${Path.analyzeDataset}`, data);
    }
  }

  handleMissingValueReplacements(data) {
    return this.apiService.post(`${V2Path.base}${V2Path.handleMissingValues}`, data);
  }

  upload(formData){
    return this.apiService.post(`${V2Path.base}${V2Path.prescreeData}`, formData);
  }

  uploadImageData(formData){
    return this.apiService.post(`${V2Path.base}${V2Path.saveImageData}`, formData);
  }

  getMasterData() {
    return this.apiService.get(`${Path.datascience}`, {});
  }
  getPreprocessingMasterData() {
    return this.apiService.get(`${Path.preprocessing}`, {});
  }

  getDatasetList(limit, offset){
    return this.apiService.get(`${V2Path.base}${V2Path.listDatasets}`, {limit, offset});
  }

  getDataset(id, type){
    
    if (type === 'scikit'){
      return this.apiService.post(`${V2Path.base}${V2Path.scikitDatasetsPreview}`, {file:id})
    }
    else if (type === 'omic'){
      return this.apiService.post(`${V2Path.base}${V2Path.dcDatasetsPreview }`, {file:id})
    }
    return this.apiService.get(`${V2Path.base}${V2Path.datasetList}${id}/${V2Path.previewDataset}`, {});
  }




  getScikitSamples(){
    return this.apiService.get(`${Path.scikitDatasets}`, {});
  }

  getOmicSamples() {
    return this.apiService.get(`${Path.omicDatasets}`, {});
  }

  getResumeChoices() {
    return this.apiService.get(`${Path.resumeChoices}`, {});
  }

  getDrugs() {
    return this.apiService.get(`${Path.drugDatasets}`, {});
  }

  getDiseases() {
    return this.apiService.get(`${Path.diseaseDatasets}`, {});
  }


  getDCRegs() {
    return this.apiService.get(`${Path.dcRegModels}`, {});
  }

  getDCClass() {
    return this.apiService.get(`${Path.dcClassModels}`, {});
  }

  getDCFeaturizers() {
    return this.apiService.get(`${Path.dcFeaturizers}`, {});
  }

 getTransferLearningSamples(){
    return this.apiService.get(`${Path.transferLearningDatasets}`, {});
  }

  addPreProcessing(id, data){
    return this.apiService.post(`${V2Path.base}${V2Path.addPreprocessing}${id}/add/`, data);
  }

  getSecondaryCharts(datasetId, qParams){
    return this.apiService.get(`${V2Path.base}${V2Path.secondaryCharts}${datasetId}/secondary-charts`, qParams);
  }

  get_NN_and_NNA_values(data){
    return this.apiService.post(`${V2Path.base}${V2Path.nnNNavalues}`, data);
  }
}

