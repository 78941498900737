import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule} from './components/layout/layout.module';
import { CoreModule} from './@core/@core.module';
import { ToastrModule} from 'ngx-toastr';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LoaderService} from './@core/services/loader.service';
import { CommonModule, DecimalPipe} from '@angular/common';
import { GdriveComponent } from './gdrive/gdrive.component';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { DataUploadComponent } from './pages/tensorflow/data-upload/data-upload.component';
import { DataUploadComponentM } from './pages/multiomics/data-upload/data-upload.component';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

//const config: SocketIoConfig = { url: 'http://82.114.65.84:6379', options: {} };


@NgModule({
  declarations: [
    AppComponent,
    GdriveComponent,
    DataUploadComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    LayoutModule,
    SharedModule,
    BrowserModule,
    
  //  SocketIoModule.forRoot(config),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    GuidedTourModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [LoaderService, DecimalPipe, GuidedTourService],
  bootstrap: [AppComponent]
})
export class AppModule { }
