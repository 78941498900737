<div class="pagination" *ngIf="!doNotLoad && dbType !== 'mongo'">
    <ul>
      <li *ngIf="currentPage -1  > 0"><a  (click)="page(currentPage-1)">Previous</a></li>
      <li *ngIf="currentPage -2  > 0" ><a  (click)="page(currentPage-2)">{{currentPage-2}}</a> </li>
      <li *ngIf="currentPage -1  > 0" ><a  (click)="page(currentPage-1)">{{currentPage-1}}</a> </li>
      <li> <a  disabled="disabled" class="active" (click)="page(currentPage)">{{currentPage}}</a></li>
      <li *ngIf="currentPage +1  <= totalPages" ><a  (click)="page(currentPage +1)">{{currentPage+1}}</a> </li>
      <li *ngIf="currentPage +2  < totalPages" ><a  (click)="page(currentPage +2)">{{currentPage+2}}</a> </li>
      <li *ngIf="currentPage +1  <= totalPages"><a (click)="page(currentPage +1)">Next </a></li>
    </ul>
</div>


<div class="pagination" *ngIf="dbType === 'mongo'">
    <ul>
      <li>
        <button class="btn btn-dark" [disabled]="disablePrev" (click)="mongoPage('prev')">Previous</button>
      </li>
      <li>
        <button  class="btn btn-dark" [disabled]="disableNext" (click)="mongoPage('next')">Next</button>
      </li>
    </ul>
</div>

<p>{{displayStr()}}</p>
