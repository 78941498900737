import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from '../../../@core/services/api.service';
import {Path} from '../../../@core/structs';


@Injectable({
  providedIn: 'root'
})
export class PackagesService {
    constructor(
      // tslint:disable-next-line:ban-types
       @Inject(PLATFORM_ID) private platformId: Object,
       public apiService: ApiService) {
    }

    createPayment(amount, packageId) {
        const data = {
          amount: amount * 100,
          currency: 'usd',
          package_id: packageId,
        };

        return this.apiService.post(Path.createPaymentIntent, data);
    }
    load(scriptUrl: string) {
    if (isPlatformBrowser(this.platformId)) {
      const node: any = document.createElement('script');
      node.src = scriptUrl;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
      console.log(`${scriptUrl} has been loaded successfully`);
    }
  }
  getPackageList(){
      return this.apiService.get(Path.packages, {limit: 200, offset: 0});
  }
}

