import { Routes } from '@angular/router';
import {GdriveComponent} from "../gdrive/gdrive.component";

export const PUBLIC_ROUTES: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'user',
        loadChildren: () =>
          import('../pages/auth/auth.module').then(
            (m) => m.AuthModule,
          ),
      },
    ],
  },
  {
    path: '',
    redirectTo: '/dataset/upload',
    pathMatch: 'full'
  },{
    path: 'gdrive',
    component: GdriveComponent,
    pathMatch: 'full'
  }
];
