import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     if (localStorage.getItem('token')) {
      if(localStorage.getItem('value') || localStorage.removeItem('countries')){
        const url = 'generate';
        const pattern = new RegExp('^' + url);
        if (!pattern.test(state.url)) { 
          localStorage.removeItem('value');
          localStorage.removeItem('countries');
        }
      }
      return true;
     } else {
       localStorage.clear();
       this.router.navigate(['auth', 'user', 'login']);
     }
  }
}
