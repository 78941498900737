<app-loader></app-loader>
<app-layout *ngIf="isLoggedIn | async; else notAuthorized">
  <router-outlet></router-outlet>
</app-layout>

<ng-template #notAuthorized>
  <router-outlet></router-outlet>
</ng-template>

<ngx-guided-tour></ngx-guided-tour>
<ng-template #packagesTemplate>
  <div class="modal-header">
    <h3 class="modal-title bold">Available packages to purchase</h3>
  </div>
  <div class="modal-body">
    <span control></span>
  </div>
  <div class="modal-footer">
    <div class="row">
      <button type="button" (click)="closeModal()" class="btn btn-primary">Submit</button>
    </div>
  </div>
</ng-template>


<div class="modal fade" id="packagesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Packages</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-template control></ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
