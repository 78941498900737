import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";


@Injectable()
export class GdriveApiService {

  constructor(public apiService: ApiService) {
  }

  getCsvFiles(accessToken) {
    let headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json',
      'GdriveAPI': true
    }
    let queryParams = {
      corpus: "user",
      "includeItemsFromAllDrives": true,
      "q": "mimeType='text/csv'",
      "supportsTeamDrives": true,
      "supportsAllDrives": true
    }
    queryParams['headers'] = headers;
    return this.apiService.get(
      'https://www.googleapis.com/drive/v3/files?', queryParams)
  }
}
