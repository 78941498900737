import { Injectable } from '@angular/core';
import {ApiService} from '../../@core/services/api.service';
import {Path, V2Path} from '../../@core/structs';

@Injectable({
  providedIn: 'root'
})
export class TensorflowService {
  constructor(public apiService: ApiService) { }

  getTensorflowLayers() {
    return this.apiService.get(`${Path.tensorflow}`, {});
  }
  getLayerData(filename) {
    return this.apiService.post(`${V2Path.base}${V2Path.methodData}`, {filename});
  }
  createTFModel(formData){
     return this.apiService.post(`${V2Path.base}${V2Path.createNNModel}`, formData);
  }
  getPretrainedModels() {
    return this.apiService.get(`${Path.pretrainedModels}`, {});
  }
  getTopperLayers() {
    return this.apiService.get(`${Path.topperLayers}`, {});
  }
  createTransferLearningModel(data){
    return this.apiService.post(`${V2Path.base}${V2Path.tlCreate}`, data);
  }
  getTLModelResult(id) {
    return this.apiService.get(`${V2Path.base}${V2Path.tlModelResult}${id}`, {});
  }
  getDatasetSummary(name) {
    return this.apiService.post(`${V2Path.base}${V2Path.datasetProps}`, { name: name });
  }
}
